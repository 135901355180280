import React, { useState, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import CareerItem from "../components/Careers/CareerItem";
import ScrollToTopButton from "../components/ScrollToTop/ScrollToTopButton";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";

const Careers = () => {
    const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);

    const loaderData = useLoaderData();

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/careers",
            title: "Careers",
        });

        const handleScroll = () => {
            const scrollTop =
                window.pageYOffset || document.documentElement.scrollTop;
            setShowScrollToTopButton(scrollTop > 0);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            {loaderData.data.length ? (
                <>
                <div className="sub-navigation white-container">
                    <div className="zigzag-top" />
                    <div className="wrapper smaller">
                        <ul>
                            {loaderData.data.map((career) => (
                                <li key={career.id}>
                                    <a
                                        href={`#${career.title.replace(/\s/g, "").toLowerCase()}`}
                                        title={`${career.title}`}
                                    >
                                        {career.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <section className="careers creme-container">
                    <div className="zigzag-bottom" />
                    <div className="wrapper smaller">
                        {loaderData.data.map((career) => (
                            <CareerItem key={career.id} career={career} />
                        ))}
                    </div>
                    </section>
                </>
            ) : (
                <section className="careers creme-container">
                    <div className="zigzag-bottom" />
                    <div className="wrapper smaller">
                        <div className="career-container no-positions">
                            <h2 className="section-title">Career Opportunities</h2>
                            <div className="flex-container two-column">
                                <div className="flex-item">
                                    <p>We currently don't have any open positions, but we're always looking for talented individuals to join our team.</p>
                                    <p>Feel free to explore our portfolio to see the kind of work we do, and check back later for new opportunities.</p>
                                </div>
                                <div className="flex-item">
                                    <div className="submit-container">
                                        <div className="submit-title">
                                            <h3>Stay Connected</h3>
                                        </div>
                                        <div className="submit-content">
                                            <p>While we don't have any positions open right now, you can still check out our work:</p>
                                            <Link
                                                to="/portfolio"
                                                title="see our portfolio"
                                                className="btn dark-purple"
                                            >
                                                See our portfolio
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {showScrollToTopButton && <ScrollToTopButton />}

        </>
    );

};

export default Careers;
