import React from "react";
import classes from "./AboutUs.module.scss";
import { useSelector } from "react-redux";
import "swiper/css/pagination";

const AboutUs = () => {
  const aboutUs = useSelector(
    (state) => state.websiteData.websiteData.header.heroContent.about_us
  );

  const ourClients = useSelector(
    (state) => state.websiteData.websiteData.ourClients
  );

  if (!aboutUs || !ourClients) {
    return null;
  }

  return (
    <section className={`${classes["about-us-section"]} creme-container`}>
      <div className="zigzag-top" />
      <div className="zigzag-bottom" />
      <div className={`${classes.wrapper} wrapper`}>
        <h2 className={`section-title ${classes.title}`}>{aboutUs.title}</h2>
        <div className={`${classes.content}`}>
          <div className={`${classes["section-text"]}`}>
            {aboutUs.text.map((paragraph, index) => (
              <p key={index} className={classes.paragraph}>{paragraph}</p>
            ))}
          </div>
          <div className={classes["section-image"]}>
            <img
              src={ourClients.section_side_img}
              alt="About Us section illustration"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
