import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './GDPRConsent.css';

const GDPRConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hasConsent = localStorage.getItem('gdprConsent');
    if (!hasConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('gdprConsent', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="gdpr-consent">
      <div className="gdpr-consent-content">
        <h3>🍪 Cookie Notice</h3>
        <p>
          We use cookies to enhance your experience and analyze our website traffic. 
          By clicking "Accept," you consent to our use of cookies.
        </p>
        <div className="gdpr-consent-buttons">
          <button className="gdpr-accept-btn" onClick={handleAccept}>
            Accept
          </button>
          <Link 
            to="/privacy_policy" 
            className="gdpr-learn-more"
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GDPRConsent; 