import React, { useEffect } from 'react';
import classes from './GamePreviewModal.module.scss';

const GamePreviewModal = ({ game, onClose }) => {
  useEffect(() => {
    // Prevent background scrolling on mobile
    if (game) {
      document.documentElement.style.position = 'fixed';
      document.documentElement.style.width = '100%';
      document.documentElement.style.height = '100%';
      document.documentElement.style.overflow = 'hidden';
    }

    return () => {
      document.documentElement.style.position = '';
      document.documentElement.style.width = '';
      document.documentElement.style.height = '';
      document.documentElement.style.overflow = '';
    };
  }, [game]);

  if (!game) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <div 
        className={classes['modal-overlay']} 
        onClick={handleOverlayClick}
        role="presentation"
      />
      <div 
        className={classes['modal']}
        role="dialog"
        aria-modal="true"
        aria-labelledby="game-modal-title"
      >
        <button 
          className={classes['mobile-exit']} 
          onClick={onClose}
          aria-label="Exit game"
        />
        <div className={classes['modal-content']}>
          <button 
            className={classes['close-button']} 
            onClick={onClose}
            aria-label="Close game preview"
          />
          <h2 id="game-modal-title">{game.title}</h2>
          <div className={classes['iframe-container']}>
            <iframe
              src={game.url}
              title={`Play ${game.title}`}
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GamePreviewModal; 