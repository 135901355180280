const local = {
  API_URL: "http://localhost:8080/website",
  recaptcha: "6Ld5ONomAAAAAOMXEzOlnZ0WmqzLbpVmD2x9EK_u",
  analytics: "G-MKF35JQWDW",
};

const dev = {
  API_URL: "https://test-website-api.inkastle.studio/website",
  // API_URL: "http://localhost:8080/website",
  recaptcha: "6Ld5ONomAAAAAOMXEzOlnZ0WmqzLbpVmD2x9EK_u",
  analytics: "G-MKF35JQWDW",
};

const prod = {
  API_URL: "https://website-api.inkastle.studio/website",
  recaptcha: "6Ld5ONomAAAAAOMXEzOlnZ0WmqzLbpVmD2x9EK_u",
  analytics: "G-MKF35JQWDW",
};
  

const getConfig = (env) => {
  if (env === "local") return local;
  if (env === "development") return dev;
  if (env === "production") return prod;
};

export const config = getConfig(process.env.REACT_APP_ENV);
