import React, { useState, useEffect } from "react";
import OurTeam from "../components/OurTeam/OurTeam";
import Statistics from "../components/Statistics/Statistics";
import Partners from "../components/Partners/Partners";
import AboutUs from "../components/AboutUs/AboutUs";
import Preview from "../components/Preview/Preview";
import ScrollToTopButton from "../components/ScrollToTop/ScrollToTopButton";
import ReactGA from "react-ga4";
import GDPRConsent from "../components/GDPRConsent/GDPRConsent";

const Home = () => {
  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Home",
    });

    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setShowScrollToTopButton(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Preview />
      <Statistics />
      {/* <OurClients /> */}
      <AboutUs />
      <Partners />
      <OurTeam />

      <GDPRConsent />
      {showScrollToTopButton && <ScrollToTopButton />}
    </>
  );
};

export default Home;
