import classes from "./Preview.module.scss";
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import GamePreviewModal from "./GamePreviewModal";

import "swiper/css/pagination";

const OurGames = () => {
  const websiteData = useSelector(
    (state) => state.websiteData.websiteData.gamesSection
  );

  const [websiteDataArranged, setWebsiteDataArranged] = useState(websiteData);
  const [selectedGame, setSelectedGame] = useState(null);

  const rearrangeWebsiteData = useCallback(() => {
    //games that are ready should be first
    const readyGames = websiteData.gamePreviews.filter((game) => game.ready);
    const notReadyGames = websiteData.gamePreviews.filter(
      (game) => !game.ready
    );
    const gamePreviews = [...readyGames, ...notReadyGames];

    setWebsiteDataArranged({ ...websiteData, gamePreviews });
  }, [websiteData]);

  useEffect(() => {
    rearrangeWebsiteData();
  }, [rearrangeWebsiteData]);

  const handleGameClick = (game) => {
    if (game.ready) {
      setSelectedGame(game);
      document.body.style.overflow = 'hidden';
    }
  };

  const handleCloseModal = () => {
    setSelectedGame(null);
    document.body.style.overflow = 'auto';
  };

  return (
    <section className={`${classes["section"]} creme-container`}>
      <div className="zigzag-top" />
      <div className="zigzag-bottom" />
      <div className={classes["container"]}>
        <div className={classes["text-container"]}>
          <h2 className={"section-title"}>{websiteData.title}</h2>
          <p>{websiteData.description}</p>
        </div>
        <div className={classes["carousel"]}>
          <Swiper
            modules={[Pagination]}
            pagination={{ clickable: true }}
            breakpoints={{
              1024: { slidesPerView: 2 },
              1200: { slidesPerView: 3 },
              1600: { slidesPerView: 4 },
              2000: { slidesPerView: 5 },
            }}
          >
            {websiteDataArranged.gamePreviews.map((game, i) => {
              const iconUrl = game.ready ? game.icon_url : game.icon_url_nr;
              const className = game.ready ? "game-ready" : "game-nr";

              return (
                <SwiperSlide key={i} className={`${classes["slide"]}`}>
                  <div
                    className={`${classes[className]}`}
                    onClick={() => handleGameClick(game)}
                  >
                    <img src={iconUrl} alt={game.title || "Game preview"} />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      <GamePreviewModal game={selectedGame} onClose={handleCloseModal} />
    </section>
  );
};

export default OurGames;
