import React, { useEffect, useState, useCallback } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchWebsiteData } from "../store/websiteDataSlice";
import headerClasses from "../components/MainNavigation/MainNavigation.module.scss";

import Footer from "../components/Footer/Footer";
import MainNavigation from "../components/MainNavigation/MainNavigation";

const Root = () => {
  const dispatch = useDispatch();
  const { websiteData, isLoading } = useSelector((state) => state.websiteData);
  const [headerBackground, setHeaderBackground] = useState(null);
  const [headerBackgroundMobile, setHeaderBackgroundMobile] = useState(null);
  const [footerBackground, setFooterBackground] = useState(null);
  const [homeBasePath, setHomeBasePath] = useState(null);
  const [isHomeScreen, setIsHomeScreen] = useState(false);

  const location = useLocation();

  // For each tab there should be different background image set in the CMS
  const changeImage = useCallback(
    (location) => {
      location = location.toLowerCase();

      if (!isLoading) {
        if (!homeBasePath) {
          setHomeBasePath(
            websiteData.header.tabs.find((tab) => tab.name === "Home").path
          );
        }

        const currentTabData =
          websiteData.header.tabs.find((tab) => tab.path === location) ||
          websiteData.header.tabs.find((tab) => tab.name === "Home");

        setHeaderBackground(currentTabData.background_image_url);
        setHeaderBackgroundMobile(currentTabData.background_image_mobile_url);

        if (!footerBackground) {
          setFooterBackground(websiteData.footer.background_img_url);
        }
      }

      setIsHomeScreen(location === homeBasePath);
    },
    [
      isLoading,
      homeBasePath,
      footerBackground,
      websiteData.header.tabs,
      websiteData.footer.background_img_url,
    ]
  );

  useEffect(() => {
    dispatch(fetchWebsiteData());
  }, [dispatch]);

  useEffect(() => {
    changeImage(location.pathname.split("/")[1]);
  }, [location, changeImage]);

  useEffect(() => {
    const currentPath = location.pathname.split("/")[1];
    const pageName =
      currentPath === ""
        ? ""
        : " - " + currentPath.charAt(0).toUpperCase() + currentPath.slice(1);
    document.title = `Inkastle Studio${pageName}`;

    if (location.key !== "default") {
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
      // setTimeout( () => window.scrollTo(0, 0) , 0);
    }
  }, [location]);

  return (
    <>
      {/* Header */}
      <header className={`${isHomeScreen ? "" : headerClasses["half-screen"]}`}>
        <picture>
          {headerBackgroundMobile ? (
            <source
              media="(max-width: 768px)"
              srcSet={`${headerBackgroundMobile}`}
            />
          ) : (
            ""
          )}
          <source media="(min-width: 769px)" srcSet={`${headerBackground}`} />
          <img src={`${headerBackground}`} alt="" />
        </picture>
        <MainNavigation />
      </header>
      {/* End of Header */}

      <Outlet />

      {/* Footer */}
      <footer style={{ backgroundImage: `url(${footerBackground})` }}>
        <Footer />
      </footer>
      {/* End of Footer */}
    </>
  );
};

export default Root;
